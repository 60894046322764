import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import Offer from "./pages/Offer";
import OfferResult from "./pages/OfferResult";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#99e4ff",
      main: "#00aeef",
      dark: "#0094cf",
      contrastText: "#fff",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/" component={Offer} exact />
          <Route path="/:id" component={OfferResult} exact />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
