import React, { useRef } from "react";
import { useHistory } from "react-router";

import { Container, Box, TextField, Button } from "@material-ui/core";

const Offer = () => {
  const inputRef = useRef(null);
  const history = useHistory();

  const useNumericMask = (event) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
  };

  const redirect = () => {
    if (inputRef.current) {
      const { value } = inputRef.current;
      history.push(`/${value}`);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box my={4} display="flex" justifyContent="center" alignItems="center">
        <TextField
          id="itemID"
          color="primary"
          style={{ marginRight: "16px" }}
          placeholder="Fırsat UID'si"
          inputRef={inputRef}
          onChange={useNumericMask}
        />
        <Button variant="contained" color="primary" onClick={redirect}>
          Sorgula
        </Button>
      </Box>
    </Container>
  );
};

export default Offer;
