import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router";

import ReCAPTCHA from "react-google-recaptcha";

import { Box, Button, Container, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { getOffer } from "../../utils/api";

const ITEM_ID_PATTERN = /\d{9,}/;

const OfferResult = () => {
  const { id } = useParams();
  const history = useHistory();

  const recaptchaRef = useRef(null);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function createOffer() {
      try {
        setLoading(true);
        if (id.match(ITEM_ID_PATTERN)) {
          const token = await recaptchaRef.current.executeAsync();
          const data = await getOffer(id, token);
          setAlert({ severity: "success", message: data.message });
        } else if (id.length > 0) {
          setAlert({
            severity: "error",
            message:
              "Bir şeyler ters gitti. Lütfen girmiş olduğunuz bilgileri kontrol ediniz!",
          });
        }
      } catch (error) {
        setAlert({ severity: "error", message: error.message });
      } finally {
        recaptchaRef.current.reset();
        setLoading(false);
      }
    }

    createOffer();
  }, [id]);

  return (
    <Container maxWidth="sm">
      {alert && (
        <>
          <Alert
            severity={alert.severity}
            onClose={() => {
              setAlert(null);
            }}
          >
            {alert.message}
          </Alert>
        </>
      )}
      {loading && (
        <Box
          my={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <CircularProgress style={{ marginBottom: "24px" }} />
          <Alert severity="info">
            Teklif oluşturuluyor. Lütfen bekleyiniz...
          </Alert>
        </Box>
      )}
      <Box my={4} display="flex" justifyContent="center" alignItems="center">
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.goBack()}
        >
          Yeni Sorgu
        </Button>
        <ReCAPTCHA
          size="invisible"
          sitekey="6Lc1uXcaAAAAAI0un3xy67eWTAkzVAypI1hxy2vg"
          ref={recaptchaRef}
        />
      </Box>
    </Container>
  );
};

export default OfferResult;
